























































import { Component, Vue } from "vue-property-decorator";

import Snack from "@/components/Snack.vue";

import restService from "@/services/restService";
import { routeToLogin } from "@/router/router";

@Component({
  components: {
    Snack,
  },
})
export default class Login extends Vue {
  username: string | null = "";
  newPassword: string = "";
  token: string | null = null;
  companyId: number = 0;
  create: boolean = false;

  checkForm(): boolean {
    return (this.$refs.form as unknown as {validate: () => boolean}).validate()
  }

  goToLogin(): void {
    routeToLogin(this.username?? undefined);
  }

  async resetPassword(): Promise<void> {
    if (! this.checkForm()) {
      return;
    }

    if (! this.companyId || ! this.username || ! this.newPassword || ! this.token) {
      return;
    }

    try {
      await restService.resetPassword(this.companyId, this.username, this.newPassword, this.token);
      routeToLogin(this.username);
    } catch (error) {
      this.errorNotify(this.$t('reset_password.veuillez-refaire-une-demande-de-reinitialisation-de-mot-de-passe'), error as Error, this.$t('reset_password.votre-lien-nest-plus-valide') as string);
    }
  }

  keyboardValidate(e: KeyboardEvent): void {
    if (["NumpadEnter", "Enter"].includes(e.key)) {
      this.resetPassword();
    }
  }

  mounted(): void {
    this.username = this.$route.query.user as string | null;
    this.token = this.$route.query.token as string | null;
    this.companyId = parseInt((this.$route.query.company_id as string | null)?? "0");
    this.create = this.$route.query.create === "true";
  }
}
